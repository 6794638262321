import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { graphql } from "gatsby"

class IndexPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <Layout location={this.props.location} title={"focus"}>
        <Seo
          title="focus privacy policy"
          keywords={[
            "focus",
            "timer",
            "pomodoro",
            "ios",
            "work",
            "productivity",
            "mobile",
            "app",
            "privacy",
            "policy",
          ]}
        />
        <h1>focus - privacy policy</h1>
        <p>
          Currently, we do not collect nor store any focus app user data. The
          app is a very simple right now and doesn’t use accounts, servers,
          databases etc.
        </p>
        <p>
          This may be a subject to change in the future versions of focus app.
          But keep in mind that focus app will always care for the privacy of
          the user and will strive to collect and store as little data as
          possible while still striving to deliver superb focus timer app
          functionality.
        </p>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
